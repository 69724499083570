@import './official-stores';

.official-stores {
  margin: 30px 0;

  .andes-carousel-free {
    &__slide {
      &:first-child {
        margin-left: 10px;
      }
    }
  }

  .carousel-mobile {
    $root: &;

    padding-left: 10px;
    #{$root}-list-element {
      margin-bottom: -10px;
      padding-bottom: 10px;

      &:last-child {
        .official-stores-slide {
          margin-right: 10px;
        }
      }
    }
  }

  .section-header {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;

    h2 {
      flex-basis: 80%;
      white-space: normal;
    }

    a {
      flex-basis: 20%;
      font-size: $font-size-14;
      font-weight: $font-weight-regular;
    }
  }
}

.official-stores-slide {
  position: relative;
  outline: none !important;

  .title-wrapper {
    bottom: 0;
    left: 0;
    height: 66px;
    position: relative;
  }

  &-action {
    display: none;
  }

  &-background {
    width: 328px;
    height: 115px;

    img {
      margin: -100px;
      width: auto;
      height: 124px;
    }
  }

  &-logo {
    position: absolute;
    height: 80px;
    bottom: 16px;
    left: 16px;
    margin: 0;

    img {
      width: 80px;
      height: 80px;
      border-radius: 8px;
      background-color: $andes-white;
      box-shadow: 0 3px 16px 0 rgb(0 0 0 / 10%);
    }
  }

  &-title {
    position: absolute;
    bottom: 50%;
    left: 119px;
    font-size: $font-size-16;
    font-weight: $font-weight-semibold;
    transform: translateY(50%);
  }
}
