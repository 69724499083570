@import './section';

section > .container {
  margin: 24px 0 4px;
}

.section-header {
  margin: 0 10px 16px;

  * {
    white-space: nowrap;
  }

  h2 {
    display: inline;
    font-size: 18px;
    font-weight: normal;
    margin: auto auto 0 0;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-font-smoothing: antialiased;
  }

  a {
    font-weight: 600;
  }
}

.section-button {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
  color: $blue;
  display: block;
  font-size: 16px;
  font-weight: 600;
  padding: 11px;
  text-align: center;
  text-decoration: none;
}
