@import './item';

.ui-item {
  @include item-size(160px, $item-height-mobile);

  &__attributes {
    margin-top: -4px;
    color: #666;
  }

  &__price-block {
    margin-top: -2px;
  }

  &__bookmark {
    display: block;
    height: 30px;
    right: 10px;
    top: 5px;
    width: 30px;

    &-icon {
      left: 8px;
      top: 10px;
      width: 14px;
    }
  }

  &__price_context_message {
    font-size: 12px;
    color: rgb(0 0 0 / 80%);
    display: block;
    height: 14px;
  }

  &__middle-title {
    @include mobile-titles;

    display: none;
  }

  &__top-title {
    @include mobile-titles;

    color: rgb(0 0 0 / 80%);
    display: block;
  }

  &__bottom-title {
    @include mobile-titles;

    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__content {
    padding: 8px 12px;
  }

  &__discount-text {
    background-color: $andes-white;
    border: 1px solid #ededed;
    border-radius: 3px;
    font-size: 11px;
    font-weight: 600;
    left: 12px;
    padding: 0 4px;
    position: absolute;
    top: -9px;
  }

  &__attributes {
    margin-top: 2px;
    font-size: 12px;
    font-weight: 300;
  }

  &__price {
    font-size: 20px;
    margin-bottom: 2px;
  }

  &.price-digits-5 {
    @include price-digits(20px);
  }

  &__action {
    margin-top: 10px;
  }

  &:hover {
    box-shadow: none;
  }
}

.ui-item::after {
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
}
