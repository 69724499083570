@import './cities';

$spacing: 8px;

.cities {
  .section-header {
    margin-left: 0;
  }

  .container {
    margin-left: 10px;
    margin-right: 10px;
  }

  .wrapper {
    overflow: hidden;
    margin: (-$spacing / 2);
  }

  .section-button {
    margin-top: $spacing;
  }
}

.hidden-city {
  display: none;
}

.city {
  position: relative;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
  height: 96px;

  &-wrapper {
    box-sizing: border-box;
    float: left;
    padding: ($spacing / 2);
    width: 50%;
  }

  .city__image {
    display: none;
  }

  .city__shadow {
    display: none;
  }

  .city__title {
    position: absolute;
    width: 100%;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #3483fa;
    font-family: $font-family-primary;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
  }
}

.watch-more-wrapper {
  width: 100%;
  margin-top: 8px;

  .watch-more-wrapper__button {
    width: 100%;
    padding: 10px;
    text-align: center;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 12%);
    background-color: #fff;
    border: unset;

    .watch-more-wrapper__text {
      width: 100%;
      font-family: $font-family-primary;
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: center;
      color: $andes-blue-500;
    }
  }
}
