@import './view-more/week-deal';
@import '~@andes/carousel-free/index';

.recommendations {
  .andes-carousel-free {
    &__slide {
      &:first-child {
        margin-left: 10px;
      }

      &:last-child {
        margin-right: 10px;
      }
    }
  }

  > .container {
    margin-bottom: -4px;

    .section-header a {
      font-size: 14px;
      color: #3483fa;
      font-weight: normal;
      padding-top: 3px;
    }
  }

  .ui-item__wrapper {
    margin-right: 0;
    margin-bottom: $andes-spacing-4;
  }

  .carousel-mobile-list {
    overflow: hidden;

    &-element {
      &:first-child .ui-item__wrapper {
        margin-left: 10px;
      }

      &:last-child .ui-item__wrapper {
        margin-right: 10px;
      }
    }
  }
}
