section.publish-with-us > .container {
  margin: 0 0 3px;
}

.publish-with-us {
  display: flex;
  background-color: $andes-white;
  padding: 60px 40px;
  margin-top: 48px;

  .publish-with-us__row-container {
    .publish-with-us__main {
      width: 100%;
      float: left;
      text-align: center;

      img {
        width: 56px;
        height: 56px;
      }

      h2 {
        width: 100%;
        font-family: $font-family-primary;
        font-size: 24px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: #4b4b4b;
        padding: 24px 0;
        margin: 0;
      }
    }

    .publish-with-us__message {
      width: 100%;
      float: left;
      text-align: center;

      p {
        padding: 0;
        margin: 0;
        font-family: $font-family-primary;
        font-size: 16px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.19;
        letter-spacing: normal;
        color: #999;

        a {
          color: $andes-blue-500;
          text-decoration: none;
        }
      }
    }

    .publish-with-us__action {
      width: 100%;
      float: left;
      text-align: center;
      padding: 64px 0 80px;

      .publish-with-us__action__btn {
        font-family: $font-family-primary;
        font-size: 18px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: $andes-blue-500;
        border: 1px solid $andes-blue-500;
        border-radius: 4px;
        padding: 14px 32px;
        text-decoration: none;
      }
    }
  }
}
