.navigation {
  .squared-item {
    &__wrapper {
      width: 120px;
      height: 120px;
      margin: (7px / 2);
    }

    img {
      width: 120px;
      height: 120px;
    }
  }

  .ui-item {
    &__wrapper {
      margin-left: 8px;
      margin-bottom: 16px;
      margin-right: 0;
    }
  }

  .view-history-slide {
    text-decoration: none;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 22px;
        height: 22px;
        margin-bottom: 8px;
      }

      span {
        text-align: center;
      }
    }
  }
}
