@import '@andes/bottom-sheet/index';
@import '@andes/button/lib/styles/modifier/quiet';
@import '@andes/dropdown/index';
@import '@andes/checkbox/index';
@import '@andes/list/index';
@import '~@andes/switch/index';
@import '@andes/textfield/index';
@import 'vis-faceted-search/src/mobile/index';

.search-bar__box {
  display: inline-block;
  height: auto;
  background-color: $andes-white;
  width: 100%;

  .faceted-search-mobile {
    box-sizing: border-box;

    .andes-form-control__field {
      border: 0;

      &:focus-visible {
        outline: none;
      }
    }

    .andes-form-control__control {
      position: relative;
    }
  }
}

.andes-bottom-sheet .andes-form-control__field {
  width: 100%;
  background-color: $andes-gray-040-solid;
  font-family: $font-family-primary;
  border: 0;
  outline: 0;
}
