.advice-mobile,
.advice-tablet {
  .andes-carousel-free {
    &__slide {
      &:first-child {
        margin-left: $andes-spacing-8;
      }

      &:last-child {
        margin-right: $andes-spacing-8;
      }
    }
  }
}
