@import '../../../styles/mixins';
@import '../../../styles/variables';
@import '@andes/autocomplete/index';
@import '~@andes/carousel-snapped/index';

@import './home';
@import '../../commons/section/mobile';
@import './exhibitor/mobile';
@import '../../commons/item/mobile';
@import '../../commons/recommendations/mobile';
@import './advertising-billboard/mobile';
@import './discovery/mobile';
@import '../../commons/squared-item/mobile';
@import './cities/mobile';
@import './navigation/mobile';
@import './search-bar/mobile';
@import './advice/mobile';
@import './most-searched/mobile';
@import './publish-with-us/mobile';
@import '../../commons/empty-screen/empty-screen';
@import './official-stores/mobile';

a {
  outline: none;
}

.carousel-mobile {
  will-change: transform;
}
