@import './exhibitor';

.exhibitor {

  .banner-re__img {
    background-image: url('https://www.portalinmobiliario.com/static_assets/home-assets/mobile_banner.png');
    background-position:  47% 100%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 56px;

    @media (max-width: 400px) {
      height: 50px;
    }
  }

  .andes-carousel-snapped {
    height: 227px;

    &__pagination {
      &--position-bottom {
        bottom: $andes-spacing-20;
      }
    }
  }
}

.exhibitor-holder {
  left: 0;
  top: 0;

  img {
    max-width: 100%;
  }
}
