.most-searched_container {
  border-top: 1px solid rgb(0 0 0 / 7%);

  button {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    color: rgb(0 0 0 / 80%);
    border: none;
    border-bottom: 1px solid rgb(0 0 0 / 7%);
    background-color: #fff;
    display: block;
    width: 100%;
    height: 60px;
    text-align: left;
    padding-left: 24px;
    outline: none;

    svg {
      width: 16px;
      float: right;
      margin-top: 6px;
      margin-right: 10px;
    }
  }

  button.active {
    svg {
      transform: rotate(180deg);
      margin-top: 3px;
    }
  }

  .panel {
    background-color: #ededed;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }

  .panel_wrapper {
    cursor: pointer;
  }

  .open {
    max-height: 300px;
  }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }

  .panel_links {
    background-color: #fafafa;
    color: #3483fa;
    text-decoration: none;
    display: block;
    padding: 15px 0;
    padding-left: 24px;
    font-family: $font-family-primary;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
  }
}
