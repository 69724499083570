@import './discovery';

.discovery {
  &__body {
    display: block;
    padding: 0 10px 20px;
    overflow: hidden;

    @media (width >= 560px) {
      display: block;
      padding-right: 18px;

      .advertising {
        min-width: 50%;
        margin-right: 8px;
      }
    }
  }

  .advertising {
    height: 190px;
    padding-left: 19px;
    display: block;

    &__image {
      padding: 20px 0;
      height: unset;

      img {
        height: 150px;
        width: 153px;
        margin: 20px 0 0;
      }
    }

    &__info {
      display: block;

      &__title {
        font-size: 10px;
        letter-spacing: 2.5px;
      }

      &__text {
        font-size: 21px;
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }

      &__button {
        font-size: 14px;
        width: 89px;
        height: 34px;
      }

      &__link {
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        color: #3a82fa;
      }
    }

    &:first-child {
      margin-bottom: 10px;
    }
  }
}
